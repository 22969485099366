import { graphql } from 'gatsby'
import React from 'react'
import AboutWrapper from '../components/About'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import ProjektListWrapper from '../components/ProjektList'
import TextHeader from '../components/TextHeader'
import Img from 'gatsby-image'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

const Index = ({ data }) => {
    const jobs = [
        {
            title: "Sharpfin",
            time: "2021 - idag",
            desc: "På Sharpfin bygger vi system för kapitalförvaltning, där jag har en kärn-roll i det lilla utvecklingsteamet. Jag gör i princip med allt från ax till limpa. Design, frontend, devops, infrastruktur, databas och vanlig backend. ",
            tags: ["Erlang", "Mnesia", "Java", "Docker", "Angular"],
            fluid: data.sharpfin.childImageSharp.fluid,
            link: "https://sharpfin.com"
        },
        {
            title: "Trustly",
            time: "2019 - 2021",
            desc: "När jag var på Trustly var jag med i ett projekt där vi skulle skriva om hela plattformen till en mikroservice arkitektur. Där jag jobbade i två olika team och ansvarade för varsin kärn-tjänst i betalningsflödet. Fick jobba med många moderna verktyg som backend utvecklare.",
            tags: ["Java", "Kubernetes", "Kafka", "React", "PostgresSQL"],
            fluid: data.trustly.childImageSharp.fluid,
            link: "https://trustly.com"
        },
        {
            title: "PJ Payment Tech",
            time: "2018 - 2019",
            desc: "Hann inte vara speciellt länge på PJ, då vi vart uppköpta av Trustly. Men fick jobba som backend utvecklare med varierande uppgifter i det existerande systemet i Java.",
            tags: ["Java", "Spring Boot", "React", "MySQL"]
        },
        {
            title: "Intinor",
            time: "2017 - 2018",
            desc: "Sommarjobbade här samt deltid under studierna sista året. Utvecklade ett Ember+ interface för videoencoders. ",
            tags: ["C/C++", "Bash", "Linux"],
            fluid: data.intinor.childImageSharp.fluid,
            link: "https://intinor.com"
        },
        {
            title: "Umeå Universitet",
            time: "2013 - 2018",
            desc: "Civilingenjörsexamen i Datavetenskap med inrikting på distribuerade system. Examensarbete om videosynkronisering från flera olika sändare.",
            tags: ["Java", "C", "Linux", "Haskell", "Prolog"],
            fluid: data.umu.childImageSharp.fluid,
            link: "https://umu.se"
        },
    ]

    return (
        <Layout className="">
            <SEO />
            <AboutWrapper />
            {/* <div>
                <TextHeader title="CV" subtitle={"Civilingenjörsexamen i datavetenskap och " + (new Date().getFullYear() - 2018) + " år som arbetande utvecklare."} className={"p-5 border-b"} />

                {jobs.map((job) => (
                    <a href={job.link}>
                        <div className={`p-5 border-b grid gap-5 ${job.link && "group hover:bg-gray-100"}`}>
                            <div className='flex justify-between items-center'>
                                <div className=''>
                                    <p className='blog-lg-text'>{job.title}</p>
                                    <p className='blog-sm-text'>{job.time}</p>
                                </div>

                                {job.fluid && <Img fluid={job.fluid} className={"w-14 h-14"} />}

                            </div>
                            <span>{job.desc}</span>
                            <div className="flex justify-between">

                                <div className='flex flex-wrap space-x-2 items-center'>
                                    {job.tags.map((tag) => (
                                        <span className="p-1 bg-gray-100 text-gray-400 text-sm py-1 px-2 ">
                                            {tag}
                                        </span>
                                    ))}
                                </div>

                                {job.link && (
                                    <div className="">
                                        <div className="p-2  rounded">
                                            <ArrowTopRightOnSquareIcon className="stroke-gray-400 h-5 w-5 group-hover:stroke-gray-900" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </a>
                ))}


            </div>
            <ProjektListWrapper /> */}

        </Layout>
    );
}

export const pageQuery = graphql`
  query {
    trustly: file(relativePath: { eq: "trustly.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      intinor: file(relativePath: { eq: "intinor.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      umu: file(relativePath: { eq: "umu.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sharpfin: file(relativePath: { eq: "sharpfin.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    allMdx(sort: {fields: frontmatter___date, order: DESC}, limit: 4) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "MMM YYYY")
            subtitle
            path
          }
          excerpt(pruneLength: 200)
          timeToRead
        }
      }
    }
  }
`
export default Index