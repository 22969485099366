import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

const About = ({ data, desc = true, ...props }) => {
    return (
        <div className="grid" {...props}>
            <Img fluid={data.emil.childImageSharp.fluid} className="border-b h-64" />
            <div className="p-5 border-b">
                <h1 className="text-5xl sm:text-6xl font-medium">Emil Nilsson</h1>
                {/* <a href="https://twitter.com/blixthalka" className=" block text-lg text-blue-400 hover:text-blue-500">@blixthalka</a> */}
            </div>
            {desc && (
                <div className="pt-5 border-b text-gray-900 inner-blogg">
                    <p className="">
                        Jag är en glad backend-utvecklare som fått upp ett intresse för frontend samt design.
                        Vilket gör att jag gillar att bygga hela lösningar och inte bara ena delen.
                    </p>
                    <p className="">
                        Just nu är jag inne på det funktionella spåret och bygger med Erlang, men är i grunden en Java utvecklare.
                        För frontend kör jag React.
                    </p>
                    <p className="">
                        Finans är även ett ämne jag har intresse för, det återspeglar sig både på bloggen och på det jag bygger.
                    </p>
                    <p>Är även en gammal orienterare, men håller mest på med vanlig löpning nu. Bästa tiden på milen är <a href="https://www.strava.com/activities/7394591530/overview">37:30</a> som jag hoppas kunna finslipa framöver. </p>
                    <p>Född och uppvuxen i Umeå, bosatt i Stockholm.</p>
                </div>
            ) }

        </div>
    )
}


const AboutWrapper = (props) => {
    return (
        <StaticQuery
            query={graphql`
            query {

                  emil: file(relativePath: { eq: "emil3.png" }) {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }

            }
        `}
            render={data => <About data={data} {...props} />}
        />
    )
}

export default AboutWrapper